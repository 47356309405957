import React from "react"
import Header from "../Header"
import oncitybooksimg from "../images/oncitybooks.png";
import windsorcensusmapsimg from "../images/windsorcensusmaps.png";
import cafacebookadsimg from "../images/cafacebookads.png"
import wikinavimg from "../images/wikinav.png"


function Entry(props){
    let codetext = "";
    if(props.code !== undefined){
        codetext = <span>| <a href={props.code}>Code</a></span>
    }
    return(
        <table>
                <caption>{props.caption}</caption>
                <colgroup>
                    <col span="1" style={{width: "50%"}}/>
                    <col span="1" style={{width: "50%"}}/>
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            <a href={props.img}><img style={{"margin-bottom": "0px"}} src={props.img}></img></a>
                        </td>
                        <td>
                            {props.desc}
                            <br/>
                            <a href={props.link}>Website</a> {codetext}
                        </td>
                    </tr>
                </tbody>
        </table>
    )
}


export default function Projects() {
    return (
        <>
            <Header title="Projects"></Header>
            <p>A list of projects I have built outside of work and their descriptions</p>
            <Entry caption="ONCityBooks" img={oncitybooksimg}
                link="https://oncitybooks.ca"
                code="https://github.com/johnhaldeman/on-city-books"
                desc="Allows people to inspect and compare their city's financial
                    situation using the Ontario Municipality Financial Information Returns (FIRs).
                    Uses React, Bootstrap, and NodeJS. Hosted with AWS.">
            </Entry>
            <br/>
            <Entry caption="Windsor Census Maps" img={windsorcensusmapsimg}
                link="http://windsorcensusmaps.com"
                code="https://github.com/johnhaldeman/WindsorCensusMaps"
                desc="Can be used to explore the 2016 Canadian Census data on a map of Windsor and Essex. 
                Uses React, Bootstrap, and Leaflet. Hosted with AWS. ">
            </Entry>
            <br/>
            <Entry caption="Facebook Canada Social/Politcal Ad Spenders Bot" img={cafacebookadsimg}
                link="https://twitter.com/FBAdSpendBotCA"
                desc="A twitter bot providing daily updates (when possible) on who is spending the most on social and political ads on Facebook in Canada. Built with NodeJS.">
            </Entry>
            <br/>
            <Entry caption="WikiNavigator - Canada" img={wikinavimg}
                link="http://wikinavigator.net/"
                code="https://github.com/johnhaldeman/WikipediaNavigator-Canada"
                desc="A game where you answer questions about Canada by navigating Wikipedia, charting your way through the Northwest Passage. Built using Electron. Published to the Microsoft Store">
            </Entry>
            <br/>
            

            
        </>
    )

}